import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Account, ChatService, UserResponse} from '../../services/chat.service';
import {MatDialog, MatPaginator, MatSlideToggleChange, MatSnackBar} from '@angular/material';
import {LocalStorage} from 'ngx-store';
import {PageEvent} from '@angular/material/paginator/typings/paginator';
import {switchMap} from 'rxjs/operators';
import {DialogResetComponent} from '../dialog-reset/dialog-reset.component';

@Component({
  selector: 'app-dialog-settings',
  templateUrl: './dialog-settings.component.html',
  styleUrls: ['./dialog-settings.component.scss']
})
export class DialogSettingsComponent implements OnInit {
  public displayedColumns: string[] = ['name', 'email', 'role', 'enabled', 'edit', 'reset'];
  public dataSource: Account[] = [];
  public userFormGroup: FormGroup;
  public currentUser: UserResponse;
  public resultsLength: number;
  public editing = false;
  public editingAccount: Account;
  public pageIndex = 1;

  @LocalStorage() token: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private formBuilder: FormBuilder,
              private chat: ChatService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
    this.userFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      role: [1, Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.chat.authByToken(this.token).subscribe(user => {
      this.currentUser = user;
      this.fetchUsers();

    });

    this.paginator.page
      .pipe(switchMap((p: PageEvent) => {
        this.pageIndex = p.pageIndex + 1;
        return this.chat.getUsers({type: 'user', limit: p.pageSize, page: p.pageIndex + 1});
      }))
      .subscribe((data) => {
        console.log(data);
        this.dataSource = data.docs;
        this.resultsLength = data.total;
      });
  }

  isMyself(account: Account): boolean {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.account._id === account._id;
  }

  fetchUsers() {
    this.chat.getUsers({
      type: 'user',
      limit: 7,
      page: this.pageIndex
    }).subscribe(paginator => {
      this.dataSource = paginator.docs;
      this.resultsLength = paginator.total;
      console.log(paginator);
    });
  }

  createNewUser() {
    if (this.userFormGroup.invalid) {
      return;
    }

    this.userFormGroup.disable();
    this.chat.createUserWithRole(this.userFormGroup.value).subscribe(account => {
      this.fetchUsers();
      this.userFormGroup.enable();
      this.userFormGroup.reset({role: 1});
    }, err => {
      this.userFormGroup.enable();
      this.showCreateError(err);
    });
  }

  toggleEnabled(account: Account, event: MatSlideToggleChange) {
    this.chat.setEnabledUser(account._id, event.checked).subscribe(user => {
      console.log(user);
    });
  }

  private showCreateError(err: Error) {
    this.snackbar.open('Error while creating user!', 'Retry', {duration: 2000})
      .onAction()
      .subscribe(actions => {
        this.createNewUser();
      });
  }

  editAccount(account: Account) {
    this.userFormGroup.reset({role: 1});
    this.editingAccount = account;
    this.userFormGroup.patchValue({name: account.name, email: account.user.email, role: account.user.role});
    this.editing = true;
    this.userFormGroup.controls['password'].disable();
  }

  cancelEditing() {
    this.editing = false;
    this.userFormGroup.reset({role: 1});
    this.editingAccount = null;
    this.userFormGroup.controls['password'].enable();
  }

  editUser() {
    if (this.userFormGroup.invalid) {
      return;
    }

    if (!this.editingAccount) {
      return;
    }

    this.chat.updateUser(this.editingAccount._id, this.userFormGroup.value).subscribe(response => {
      if (response) {
        this.fetchUsers();
        this.showUpdateUserSuccess();
        this.cancelEditing();
      } else {
        this.showErrorUpdating();
      }
    });
  }

  private showUpdateUserSuccess() {
    this.snackbar.open('User has updated!', null, {duration: 2000});
  }

  private showErrorUpdating() {
    this.snackbar.open('Error while updating user', 'Retry', {duration: 3000})
      .onAction().subscribe(() => {
      this.editUser();
    });
  }

  resetPassword(account: Account) {
    this.dialog.open<DialogResetComponent, Account>(DialogResetComponent, {
      data: account
    }).afterClosed().subscribe(value => {
      if (value) {
        this.snackbar.open('Password has been reset!', null, {duration: 1000});
      }
    });
  }
}
