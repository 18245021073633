import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'optimizedDate'
})
export class OptimizedDatePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    const currentDate = new Date();
    const date = new Date(value);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const day = days[date.getDay()];
    let hr = date.getHours();
    const min = date.getMinutes();
    let strMin = '00';
    if (min < 10) {
      strMin = '0' + min;
    } else {
      strMin = min.toString();
    }
    let ampm = 'am';
    if (hr > 12) {
      hr -= 12;
      ampm = 'pm';
    }

    const month  = months[date.getMonth()];

    if (currentDate.getFullYear() === date.getFullYear() &&
      currentDate.getMonth() === date.getMonth() &&
      currentDate.getDate() === date.getDate()) {
      return `${hr}:${strMin}${ampm}`;
    }

    if (currentDate.getFullYear() === date.getFullYear() &&
      currentDate.getMonth() === date.getMonth()) {
      return day;
    }


    if (currentDate.getFullYear() === date.getFullYear()) {
      return `${month} ${date.getDate()}`;
    }


    return date.toLocaleDateString();
  }

}
