import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import * as io from 'socket.io-client';
import {Observer} from 'rxjs/internal/types';
import {share} from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class ChatService {
  public static readonly Host = environment.API_CHAT_URL;
  public static readonly TokenKey = 'APP_CHAT_TOKEN';
  public readonly socket = io(ChatService.Host);

  private getUrl(...endpoints: string[]): string {
    return ChatService.Host.concat(...endpoints);
  }

  constructor(private http: HttpClient) {

  }

  public joinRoom(roomId: string) {
    this.socket.emit('join-room', roomId);
  }

  public joinNewRoom(roomId: string): Observable<Room> {
    return this.http.post<Room>(this.getUrl(`/rooms/${roomId}/join`), {});
  }

  public fromEvent<T = any>(event: string): Observable<T> {
    return Observable.create((observer: Observer<T>) => {
      this.socket.on(event, (data: T) => {
        observer.next(data);
      });
    }).pipe(share());
  }


  authenticateSocket(token: string) {
    this.socket.emit('authenticate', {token});
    console.log('Authenticating ');
  }


  public postCreateGuest({name, email}: { name: string, email: string }): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.getUrl('/guests'), {name, email});
  }

  public getMessagesOfRoom(roomId: string): Observable<DataMessage[]> {
    return this.http.get<DataMessage[]>(this.getUrl('/messages/', roomId, '/messages'));
  }

  public createTextMessage({body, roomId}: { body: string, roomId: string }): Observable<DataMessage> {
    return this.http.post<DataMessage>(this.getUrl('/messages'), {type: 1, body, roomId});
  }

  public getReadyRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.getUrl('/rooms/ready'));
  }

  public getLastMessageOfRoom(roomId: string): Observable<DataMessage> {
    return this.http.get<DataMessage>(this.getUrl(`/rooms/${roomId}/last-message`));
  }

  public getOpenRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.getUrl('/rooms/open'));
  }
  public getClosedRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.getUrl('/rooms/closed'));
  }

  public getMyClosedRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.getUrl('/rooms/my/closed'));
  }

  public getMyJoinedRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.getUrl('/rooms/my/joined'));
  }

  public createRoom({name, maxLength}: { name: string, maxLength: number }): Observable<Room> {
    return this.http.post<Room>(this.getUrl('/rooms'), {name, maxLength});
  }

  public authByToken(token: string): Observable<UserResponse> {
    return this.http.get<UserResponse>(this.getUrl('/users/me'));
  }

  public createUserWithRole({name, email, password, role}: { name: string, email: string, password: string, role: number }): Observable<Account> {
    return this.http.post<Account>(this.getUrl('/users/create'), {name, email, password, role});
  }

  public getMembersOfRoom(roomId: string): Observable<Account[]> {
    return this.http.get<Account[]>(this.getUrl('/rooms/', roomId, '/members'));
  }

  public hasStoredToken(): boolean {
    return !!this.getStoredToken();
  }

  public getStoredToken(): string {
    return localStorage.getItem(ChatService.TokenKey);
  }

  public saveToken(token: string) {

    localStorage.setItem(ChatService.TokenKey, token);
  }

  public clearToken() {
    localStorage.removeItem(ChatService.TokenKey);
  }

  public emitTypingToRoom(roomId: string) {
    this.socket.emit('typing', roomId);
  }

  public leaveRoom(roomId: string): Observable<Room> {
    return this.http.post<Room>(this.getUrl('/rooms/', roomId, '/leave'), {});
  }

  public closeRoom(roomId: string): Observable<Room> {
    return this.http.post<Room>(this.getUrl('/rooms/', roomId, '/close'), {});
  }

  public createUser(value: { name: string, email: string, password: string, role: number }): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.getUrl('/users'), value);
  }

  public updateUser(accountId: string, data: { name: string, email: string, password: string, role: number }): Observable<User> {
    return this.http.patch<User>(this.getUrl('/users/', accountId), data);
  }

  public resetUserPassword(accountId: string, password: string): Observable<User> {
    return this.http.patch<User>(this.getUrl('/users/', accountId, '/reset-password'), {password});
  }


  public getUsers(params: { type?: string, page: any, limit: any } = {page: 1, limit: 10}): Observable<PaginationOf<Account>> {
    return this.http.get<PaginationOf<Account>>(this.getUrl('/users'), {params});
  }

  public setEnabledUser(accountId: string, enabled: boolean): Observable<User> {
    return this.http.put<User>(this.getUrl('/users/', accountId, '/enabled'), {enabled});
  }

  public signInUser(value: { email: string, password: string }): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.getUrl('/users/sign-in'), value);
  }

  public hasAdmin(): Observable<{hasAdmin: boolean}> {
    return this.http.get<{hasAdmin: boolean}>(this.getUrl('/users/has-admin'));
  }

  public createFirstAdmin({name, email, password}: {name: string, email: string, password: string}): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.getUrl('/users/first-admin'), {name, email, password});
  }

  public setGlobalOnlineStatus(isOnline: boolean): Observable<DataPresence> {
    if (isOnline) {
      return this.http.get<DataPresence>(this.getUrl('/presence/online'));
    } else {
      return this.http.get<DataPresence>(this.getUrl('/presence/offline'));
    }
  }

  public getGlobalPresence(): Observable<DataPresence> {
    return this.http.get<DataPresence>(this.getUrl('/presence'));
  }
}

export interface DataMessage extends BaseModel {
  sender: string & Account;
  room: string & Room;
  type: number;
  body: string;
}

export interface Room extends BaseModel {
  name: string;
  status: number;
  members: string[] & Account[];
  creator: string & Account;
  maxLength: number;
  lastMessage?: string;
}

export interface UserResponse {
  account: Account;
  expiresIn: number;
  token: string;
}

export interface User extends BaseModel {
  email: string;
  password: string;
  status?: number;
  enabled?: boolean;
  role: number;
}

export interface Account extends BaseModel {
  meta: Meta;
  type: 'guest' | 'user';
  name: string;
  user: User & string;
}

export interface Meta {
  email: string;
}

export interface BaseModel {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
}


export interface DataPresence extends BaseModel {
  isOnline: boolean;
}


export interface PaginationOf<T> {
  total?: number;
  offset: number;
  limit: number;
  docs: T[];
}
