import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appRandomColor]'
})
export class RandomColorDirective implements OnInit{

  @Input('appRandomColor') appRandomColor = '';

  public avatarColors = [
    '#FBE9E7',
    '#F3E5F5',
    '#E8EAF6',
    '#EEEEEE',
    '#E0F2F1',
    '#E3F2FD',
    '#FFEBEE'
  ];

  constructor(private element: ElementRef<HTMLDivElement>, private renderer: Renderer2) {

  }


  public getValue(value: string) {
    const number = value.split('').map(x => x.charCodeAt(0)).reduce((a, b) => a + b, 0);
    return this.avatarColors[number % (this.avatarColors.length - 1)];
  }

  public getRandomColor() {
    const index = Math.round(Math.random() * (this.avatarColors.length - 1));
    return this.avatarColors[index];
  }

  ngOnInit(): void {
    this.element.nativeElement.style.backgroundColor = this.getValue(this.appRandomColor);
  }

}
