import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Account, ChatService} from '../../services/chat.service';

@Component({
  selector: 'app-dialog-reset',
  templateUrl: './dialog-reset.component.html',
  styleUrls: ['./dialog-reset.component.scss']
})
export class DialogResetComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<DialogResetComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Account,
              private builder: FormBuilder,
              private snackBar: MatSnackBar,
              private chat: ChatService) {
    this.formGroup = this.builder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit() {

  }

  reset() {
    if (this.formGroup.invalid) {
      return;
    }

    const {password, confirmPassword} = <{ password: string, confirmPassword: string }>(this.formGroup.value);
    if (password !== confirmPassword) {
      this.snackBar.open('Passwords do not match!', null, {duration: 2000});
      return;
    }

    this.formGroup.disable();
    this.chat.resetUserPassword(this.data._id, password).subscribe(res => {
      this.dialogRef.close(res);
    }, error => {
      this.formGroup.enable();
      this.snackBar.open('Error while resetting password!', 'Retry', {duration: 2000})
        .onAction()
        .subscribe(() => this.reset());
    });

  }

}
