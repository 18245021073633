import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocalStorage} from 'ngx-store';
import {Router} from '@angular/router';
import {ChatService} from '../../services/chat.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  @LocalStorage() token: string;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private chatService: ChatService,
              private snackBar: MatSnackBar) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {

  }

  onLogin() {
    console.log('enter');
    if (this.form.invalid) {
      return;
    }
    this.form.disable();
    this.chatService
      .signInUser(this.form.value)
      .subscribe(value => {
        this.token = value.token;
        this.router.navigate(['']);
      }, err => {
        this.snackBar.open('Login failed!', null, {duration: 2000});
        this.form.enable();
      });
  }
}
