import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorage} from 'ngx-store';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  @LocalStorage() token: string;

  constructor(private auth: AuthService,
              private router: Router) {

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    if (this.token) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
