import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ChatService} from '../../services/chat.service';
import {LocalStorage} from 'ngx-store';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private userSubscription: Subscription;

  @LocalStorage() token: string;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private chatService: ChatService) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      name: ['', Validators.required],
    });
  }


  ngOnInit() {

  }

  public onRegister() {
    if (this.form.invalid) {
      return;
    }

    const {password, confirmPassword} = <{ password: string, confirmPassword }>(this.form.value);
    if (password !== confirmPassword) {
      return;
    }

    this.chatService.createFirstAdmin({
      email: this.form.value.email,
      password: this.form.value.password,
      name: this.form.value.name
    }).subscribe(res => {
      this.token = res.token;
      this.router.navigate(['']);
    });
  }

  ngOnDestroy(): void {

  }
}
