import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {HomeComponent} from './components/home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {WebStorageModule} from 'ngx-store';
import {AuthGuard} from './guards/auth.guard';
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressHttpModule} from '@ngx-progressbar/http';
import {NgProgressRouterModule} from '@ngx-progressbar/router';
import {DialogSettingsComponent} from './components/dialog-settings/dialog-settings.component';
import {ChatAuthInterceptor} from './interceptors/chat-auth.interceptor';
import {MorePipe} from './pipes/more.pipe';
import {VirtualScrollModule} from 'angular2-virtual-scroll';
import {OptimizedDatePipe} from './pipes/optimized-date.pipe';
import {RolePipe} from './pipes/role.pipe';
import {DialogResetComponent} from './components/dialog-reset/dialog-reset.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import { RandomColorDirective } from './directives/random-color.directive';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    DialogSettingsComponent,
    MorePipe,
    OptimizedDatePipe,
    RolePipe,
    DialogResetComponent,
    NotFoundComponent,
    RandomColorDirective,
    DialogConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgProgressModule.forRoot(),
    NgProgressHttpModule,
    NgProgressRouterModule,
    VirtualScrollModule,
    WebStorageModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatRippleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatTableModule,
    MatSelectModule,
    MatSnackBarModule,
    MatPaginatorModule
  ],
  providers: [AuthGuard, {
    useClass: ChatAuthInterceptor,
    provide: HTTP_INTERCEPTORS,
    multi: true,
  }],
  bootstrap: [AppComponent],
  entryComponents: [DialogSettingsComponent, DialogResetComponent, DialogConfirmationComponent]
})
export class AppModule {
}
