import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(role: number, args?: any): string {
    switch (role) {
      case 1:
        return 'Staff';
      case 2:
        return 'Administrator';
      default:
        return 'Unknown';
    }
  }

}
