import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSlideToggleChange} from '@angular/material';
import {DialogSettingsComponent} from '../dialog-settings/dialog-settings.component';
import {animate, keyframes, query, stagger, state, style, transition, trigger} from '@angular/animations';
import {LocalStorage, LocalStorageService} from 'ngx-store';
import {Account, ChatService, DataMessage, Room, UserResponse} from '../../services/chat.service';
import {debounceTime, filter, mapTo} from 'rxjs/operators';
import {merge} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {DialogConfirmationComponent} from '../dialog-confirmation/dialog-confirmation.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('slide', [
      state('void', style({transform: 'translateX(200px)', opacity: 0})),
      transition('void => *', animate('.1s ease'))
    ]),
    trigger('sendAnimation', [
      state('hide', style({
        opacity: 0,
        transform: 'scale(0) rotate(-25deg)'
      })),
      state('show', style({
        opacity: 1,
        transform: 'scale(1) rotate(0)'
      })),
      transition('hide <=> show', animate('.15s ease-out'))
    ]),
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({opacity: 0}), {optional: true}),
        query(':enter', stagger('-100ms', [
          animate('.2s ease-out', keyframes([
            style({opacity: 0, transform: 'translateY(25%)', offset: 0}),
            style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  @LocalStorage() token: string;

  public floatSearch = false;
  public isOnlinePresence = false;
  public showInfo = true;
  public showStartConversation = true;
  public disableJoin = false;
  public loading = {
    users: true,
    chat: false,
    info: false,
    global: true,
  };

  public currentAccount: UserResponse;
  public rooms: Room[];
  public selectedRoom: Room;
  public messages: DataMessage[] = [];
  public roomMembers: Account[] = [];


  public messageControl = new FormControl('', [Validators.required, this.noWhitespaceValidator]);
  public showTypingIndicator = false;

  public messageListScrollHeight = 0;
  public messageListScrollTop = 0;
  public title = 'Recent';

  private messageList: ElementRef<HTMLDivElement>;

  public onlineSockets: Map<string, boolean> = new Map<string, boolean>();
  public isRecentRoomShow = true;

  @ViewChild('messageList') set content(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.messageList = content;
      content.nativeElement.scrollTop = content.nativeElement.scrollHeight;
    }
  }

  constructor(private router: Router,
              private dialog: MatDialog,
              private chat: ChatService,
              private storage: LocalStorageService) {
  }


  private scrollBottom() {
    if (!this.messageList) {
      return;
    }

    this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight + 100;
  }

  ngOnInit() {
    this.chat.socket.connect();
    this.messageControl
      .valueChanges
      .pipe(filter(a => !!a), debounceTime(100))
      .subscribe(() => this.chat.emitTypingToRoom(this.selectedRoom._id));

    this.initialAuth();
    this.chat.socket.connect();
  }

  public toggleShowInfo() {
    this.showInfo = !this.showInfo;
  }


  startConversation() {
    this.chat.joinNewRoom(this.selectedRoom._id)
      .subscribe(room => {
        this.chat.joinRoom(room._id);
        this.roomSelected(room);
        this.showStartConversation = !this.hasJoinedRoom(room);
        this.initiateRooms();
      });
  }

  openSettings() {
    this.dialog.open(DialogSettingsComponent, {
      disableClose: true
    });
  }

  private checkToken() {

    if (this.token) {
      this.chat
        .authByToken(this.token)
        .subscribe(res => {
          this.currentAccount = res;
          this.loading.global = false;
          this.completeEverything();
        }, err => {
          this.signOut();
        });
    }
  }

  private completeEverything() {
    this.chat
      .getGlobalPresence()
      .subscribe(pre => {
        if (pre) {
          this.isOnlinePresence = pre.isOnline;
        }
      });
  }

  private initialAuth() {

    this.chat.fromEvent<Room>('room-close').subscribe(room => {
      console.log(room);
    });

    this.chat.fromEvent<{ accountId: string, online: boolean }>('presence').subscribe(pre => {
      console.log(pre);
      this.onlineSockets.set(pre.accountId, pre.online);
    });

    this.chat.fromEvent<Room>('room-created').subscribe(room => {
      console.log(room);
      if (this.isRecentRoomShow) {

        this.chat.getLastMessageOfRoom(room._id).subscribe(message => {
          if (message) {
            room.lastMessage = message.body;
          }
        });
        this.rooms.unshift(room);

      }
    });


    this.chat.fromEvent<{ accountId: string }>('start-typing').subscribe(user => {
      if (this.selectedRoom.members.includes(user.accountId)) {
        this.showTypingIndicator = true;
        this.scrollBottom();
      }
    });

    this.chat.fromEvent<{ accountId: string }>('stop-typing').subscribe(user => {
      if (this.selectedRoom.members.includes(user.accountId)) {
        this.showTypingIndicator = false;
      }
    });


    this.chat.fromEvent<DataMessage>('message').subscribe(message => {
      if (this.selectedRoom) {
        if (message.room === this.selectedRoom._id) {
          this.messages.push(message);
          this.showTypingIndicator = false;
          this.selectedRoom.lastMessage = message.body;
          this.scrollBottom();
        }
      }
    });

    merge(this.chat.fromEvent('connect').pipe(mapTo(true)),
      this.chat.fromEvent('disconnect').pipe(mapTo(false)))
      .subscribe(isConnected => {
        console.log('Socket.io is ' + (isConnected ? 'connected' : 'disconnected'));
        if (isConnected) {
          if (this.token) {
            this.chat.authenticateSocket(this.token);
          }
        }
      });

    this.chat.fromEvent('authenticated')
      .subscribe(() => {
        this.checkToken();
        this.initiateRooms();
      });
  }

  private initiateRooms() {
    this.loading.users = true;
    this.rooms = [];
    this.chat.getOpenRooms().subscribe(rooms => {
      console.log(rooms);
      this.loading.users = false;
      this.rooms = rooms.reverse();
      this.rooms.forEach(room =>
        this.chat.getLastMessageOfRoom(room._id).subscribe(message => {
          if (message) {
            room.lastMessage = message.body;
          }
        }));
    });

  }

  private initiateClosedRooms() {
    this.loading.users = true;
    this.rooms = [];
    this.chat.getClosedRooms().subscribe(rooms => {
      this.loading.users = false;
      this.rooms = rooms.reverse();
      this.rooms.forEach(room =>
        this.chat.getLastMessageOfRoom(room._id).subscribe(message => {
          if (message) {
            room.lastMessage = message.body;
          }
        }));
    });
  }


  private initiateMyClosedRooms() {
    this.loading.users = true;
    this.rooms = [];
    this.chat.getMyClosedRooms().subscribe(rooms => {
      this.loading.users = false;
      this.rooms = rooms.reverse();
      this.rooms.forEach(room =>
        this.chat.getLastMessageOfRoom(room._id).subscribe(message => {
          if (message) {
            room.lastMessage = message.body;
          }
        }));
    });
  }

  roomSelected(room: Room) {
    this.loading.chat = true;
    this.selectedRoom = room;
    this.chat
      .getMessagesOfRoom(room._id)
      .subscribe(value => {
        this.messages = value;
        this.selectedRoom.lastMessage = this.messages[this.messages.length - 1].body;
        setTimeout(() => {
          this.loading.chat = false;
          this.scrollBottom();
        }, 400);
      });

    this.showStartConversation = !this.hasJoinedRoom(room);
    this.disableJoin = room.maxLength === room.members.length;
    if (this.hasJoinedRoom(room)) {
      this.chat.joinRoom(room._id);
    }

    this.chat.getMembersOfRoom(room._id).subscribe(res => {
      this.roomMembers = res;
    });

  }

  hasJoinedRoom(room: Room) {
    return room.members.includes(this.currentAccount.account._id);
  }

  onSendMessage() {
    if (this.messageControl.invalid) {
      return;
    }

    const message = this.messageControl.value.trim();

    if (message === '') {
      return;
    }


    this.chat.createTextMessage({body: message, roomId: this.selectedRoom._id})
      .subscribe(value => {
        console.log(value);
        this.messageControl.reset();
      });
  }

  togglePresence(e: MatSlideToggleChange) {
    this.chat.setGlobalOnlineStatus(e.checked).subscribe(presence => {
      this.isOnlinePresence = presence.isOnline;
      console.log(presence);
    }, err => {
      this.isOnlinePresence = !e.checked;
    });
  }

  onScroll(event: Event) {
    const ul = event.target as HTMLUListElement;
    this.floatSearch = ul.scrollTop >= 5;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  public isMyMessage(message: DataMessage): boolean {
    return (message.sender === this.currentAccount.account._id);
  }

  getToolTipPosition(message: DataMessage): string {
    if (message.type === 2) {
      return 'above';
    }
    if (message.sender === this.currentAccount.account._id) {
      return 'left';
    }
    return 'right';
  }

  isUserOnline(account: Account): boolean {
    return !this.onlineSockets.get(account._id);
  }

  onLeaveRoom(room: Room) {
    this.chat.leaveRoom(room._id).subscribe(res => {
      this.roomSelected(res);
      this.showStartConversation = true;
      this.disableJoin = false;
      this.initiateRooms();
    });
  }

  closeRoom(room: Room) {
    this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Close room',
        content: 'Are you sure you want to close this room?'
      }
    }).afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }

      this.chat.closeRoom(room._id).subscribe(res => {
        console.log(res);
        this.selectedRoom = null;
        this.messages = [];
        this.initiateRooms();
      });
    });
  }

  signOut() {

    this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'Logging out',
        content: 'Are you sure you want to log out?'
      }
    }).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.storage.clear('prefix');
        this.router.navigate(['/login']);
        this.chat.socket.close();
        this.chat.socket.removeAllListeners();
      }
    });

  }

  getRecentRooms() {
    this.isRecentRoomShow = true;
    this.title = 'Recent Rooms';
    this.initiateRooms();
  }

  getClosedRooms() {
    this.isRecentRoomShow = false;
    this.title = 'My Closed Rooms';
    this.initiateMyClosedRooms();
  }

  getJoinedRooms() {
    this.isRecentRoomShow = false;
    this.title = 'My Joined Rooms';
    this.initiateMyJoinedRooms();
  }

  private initiateMyJoinedRooms() {
    this.loading.users = true;
    this.rooms = [];
    this.chat.getMyJoinedRooms().subscribe(rooms => {
      this.loading.users = false;
      this.rooms = rooms.reverse();
      this.rooms.forEach(room =>
        this.chat.getLastMessageOfRoom(room._id).subscribe(message => {
          if (message) {
            room.lastMessage = message.body;
          }
        }));
    });
  }
}
